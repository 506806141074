import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import classes from "./styles.module.css";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { useAppDispatch } from "../../app/hooks";
import { resetPassword } from "../../features/auth/auth.slice";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { light } from "../../css/color";
import { EnduvoButton } from "../../components/EnduvoButton";

export const ResetPassword = () => {
  const [email, setemail] = React.useState("");
  const [resetPasswordKey, setresetPasswordKey] = React.useState("");
  const [resetPasswordAlert, setResetPasswordAlert] = React.useState("");
  const [showresetPasswordlert, setShowresetPasswordAlert] = React.useState(false);
  const handleAlertClose = () => setShowresetPasswordAlert(false);

  const seturiAndResetPasswordKey = async () => {
    const url = window.location.href;
    const pieces = url.split("?");
    if (pieces[1]) {
      const urlparam = pieces[1];
      const urlparamSplitFind = urlparam.split("&");
      let urlArray = [];
      let email = "";
      let resetPasswordKey = "";
      for (let i = 0; i < urlparamSplitFind.length; i += 1) {
        urlArray = urlparamSplitFind[i].split("=");
        const [key, value] = urlArray;
        if (key === "resetPasswordKey") resetPasswordKey = value;
        if (key === "email") email = decodeURIComponent(value);
      }
      setemail(email);
      setresetPasswordKey(resetPasswordKey);
    }
  };

  useEffect(() => {
    seturiAndResetPasswordKey();
    return () => {
      seturiAndResetPasswordKey();
    };
  }, []);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordFormatErr, setpasswordFormatErr] = React.useState("");
  const [errorMsg, seterrorMsg] = React.useState("");
  const [termsOfUse, settermsOfUse] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    settermsOfUse(event.target.checked);
  };
  const validatePassword = (newPassword: String) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+={}\\;:"<>,./?])(?=.*[A-Z*])[a-zA-Z0-9!@#$%^&*()_+={}\\;:"<>,./?]{6,16}$/;
    return re.test(String(newPassword));
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: String) => {
    if (event.target.value) {
      switch (field) {
        case "password":
          if (validatePassword(event.target.value)) setpasswordFormatErr("");
          else setpasswordFormatErr("Password format is invalid.");
          setPassword(event.target.value);
          break;
        case "confirmPassword":
          if (password !== event.target.value) seterrorMsg("Both passwords don't match.");
          else seterrorMsg("");
          setConfirmPassword(event.target.value);
          break;
        default:
          break;
      }
    }
  };
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handle = async () => {
    const resultAction = await dispatch(resetPassword({ email, password, resetPasswordKey, termsOfUse }));
    if (resetPassword.fulfilled.match(resultAction)) {
      if (resultAction.payload.success) {
        setResetPasswordAlert("Success.");
        history.push("/");
      } else if (!resultAction.payload.success) setResetPasswordAlert(resultAction.payload.error.message);
      else setResetPasswordAlert("Please try again.");
      setShowresetPasswordAlert(true);
    }
  };

  const restPassword = async () => {
    const validate = () => password && confirmPassword && resetPasswordKey && termsOfUse && !passwordFormatErr && !errorMsg;
    if (validate()) {
      handle();
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "rgb(192, 192, 192)",
        height: "110vh",
        paddingLeft: "68px",
        paddingTop: "31px",
      }}
    >
      <Stack alignItems="center">
        <Stack
          justifyContent="center"
          sx={{ borderRadius: 5, width: "530px", bgcolor: light.bgColor3, mt: "40px", p: "40px" }}
          spacing={2}
        >
          <Stack alignItems="center">
            <Typography variant="h4" component="h2" color={light.textColor1}>
              Set password
            </Typography>
          </Stack>
          {passwordFormatErr || errorMsg ? (
            <>
              <Typography variant="caption" color="red">
                {passwordFormatErr}
              </Typography>
              <Typography variant="caption" color="red">
                {errorMsg}
              </Typography>
            </>
          ) : null}
          <Typography variant="caption" color={light.textColor1}>
            Password
          </Typography>
          <TextField
            onChange={(e) => handleTextChange(e, "password")}
            type="password"
            InputProps={{ style: { color: light.textColor1, height: "50px" } }}
            id="outlined-basic"
            placeholder="********"
            className={classes.inputField}
          />
          <Typography variant="caption" color={light.textColor1}>
            Confirm Password
          </Typography>
          <TextField
            onChange={(e) => handleTextChange(e, "confirmPassword")}
            type="password"
            InputProps={{ style: { color: light.textColor1, height: "50px" } }}
            id="outlined-basic"
            placeholder="********"
            className={classes.inputField}
          />
          <Typography variant="body2" color="#797979" sx={{ width: "490px" }}>
            Password must be minimum six characters including one uppercase letter, one special character and alphanumeric
            characters.
          </Typography>
          <Stack flexDirection="row">
            <Checkbox checked={termsOfUse} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
            <Typography variant="caption" color="#A4A4A4" sx={{ mt: "10px" }}>
              I agree to the
            </Typography>
            <Link variant="caption" href="https://enduvo.com/terms-of-use/" sx={{ ml: "5px", mt: "10px" }}>
              {" "}
              Terms of use.
            </Link>
          </Stack>
          <Stack>
            <EnduvoButton
              startIcon={null}
              action={restPassword}
              buttonText="Set Password"
              width={null}
              height={null}
              disabled={false}
            />
          </Stack>
          {resetPasswordAlert ? (
            <Typography variant="caption" color="red">
              {resetPasswordAlert}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      <Stack>
        <Snackbar open={showresetPasswordlert} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="success">
            {resetPasswordAlert}
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  );
};
